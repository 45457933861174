/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import cTwo from "../../assets/img/country-img/c-2.jpg";
import cthree from "../../assets/img/country-img/c-3.jpg";
import cfour from "../../assets/img/country-img/c-4.jpg";
import cfive from "../../assets/img/country-img/c-5.jpg";
import csix from "../../assets/img/country-img/c-6.jpg";

export default () => {
  return (
    // className "owl-theme" is optional
    <OwlCarousel
      className="owl-theme countries-active "
      items={4}
      loop
      margin={10}
      nav
    >
      <div class="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cTwo} alt="" />
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="#">AUSTRALIA</a>
            </h5>
          </div>
        </div>
      </div>
      <div class="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cthree} alt="" />
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="#">CANADA</a>
            </h5>
          </div>
        </div>
      </div>
      <div class="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cfour} alt="" />
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="#">United States</a>
            </h5>
          </div>
        </div>
      </div>
      <div class="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={cfive} alt="" />
            <div className="countries-item__top-img-link">
              <a href="#">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="#">GERMANY</a>
            </h5>
          </div>
        </div>
      </div>
      <div class="item countries-item img-top pl-4 pr-4">
        <div className="countries-item__top">
          <div className="countries-item__top-img">
            <img src={csix} alt="" />
            <div className="countries-item__top-img-link">
              <a href="#">
                <i className="fal fa-plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="countries-item__bottom d-flex align-items-center">
          <div className="countries-item__bottom-content">
            <h5 className="countries-item__bottom-content-title">
              <a href="#">POLAND</a>
            </h5>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};
