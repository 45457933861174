import React from "react";

import { Link } from "react-router-dom";

import pt from "../../assets/img/about-us/ab-us.jpg";
import about from "../../assets/img/services/services-1.jpg";

import abouttwo from "../../assets/img/featurs/featurs-1.jpg";

import services from "../../assets/img/featurs/services.jpg";

import aboutus from "../../assets/img/about-us/ab-m.jpg";

import Slide from "./Sliders-two";

const Main = () => {
  return (
    <div>
      {/* Fullscreen search */}
      <div className="search-wrap">
        <div className="search-inner">
          <i className="fal fa-times search-close" id="search-close" />
          <div className="search-cell">
            <form method="get">
              <div className="search-field-holder">
                <input
                  type="search"
                  className="main-search-input"
                  placeholder="Search Entire Store..."
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end fullscreen search */}
      {/* header area end here */}
      {/* page title area start */}
      <div
        className="page-title__area pt-110"
        style={{ backgroundImage: "url(" + pt + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                <h3 className="pb-100">Services</h3>
              </div>
            </div>
          </div>
        </div>
        <nav className="breadccrumb-bg">
          <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items">
              <Link to="/">Home</Link>
            </li>
            <li className="bd-items bdritems">|</li>
            <li className="bd-items">
              <Link to="/services">Services</Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* page title area end */}
      {/* services featurs start */}
      <div className="services-featurs pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="services-fimg">
                <img src={about} alt="" />
              </div>
              <div className="section_title_wrapper pt-40">
                <span className="subtitle">Featured Services</span>
                <h2 className="section-title">
                  We Take The Challenge to Make The Life Easier
                </h2>
                <p className="pt-30 pb-25 mr-25">
                  For the last 25 years, We have helped students, business
                  persons, tourists, clients with medical needs. There are many
                  variations of passages of Lorem Ipsum available, but the
                  majority have suffered alteration
                </p>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="services-items services-itm-color mb-30">
                    <h4 className="services-items__title">
                      Proper <br />
                      Information
                    </h4>
                    <p>
                      Work permit approval in higher education is designed for
                      career professionals seeking
                    </p>
                    <a className="aborder1" href="business-visa.html">
                      <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="services-items services-itm-color2 mb-30">
                    <h4 className="services-items__title">
                      Advice &amp; <br />
                      Consultancy
                    </h4>
                    <p>
                      Work permit approval in higher education is designed for
                      career professionals seeking
                    </p>
                    <a className="aborder2" href="business-visa.html">
                      <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="services-items services-itm-color3 mb-30">
                    <h4 className="services-items__title">
                      Tour &amp; Travel <br />
                      Guidelines
                    </h4>
                    <p>
                      Work permit approval in higher education is designed for
                      career professionals seeking
                    </p>
                    <a className="aborder3" href="business-visa.html">
                      <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="services-items services-itm-color4 mb-30">
                    <h4 className="services-items__title">
                      Education <br />
                      Tips and Tricks
                    </h4>
                    <p>
                      Work permit approval in higher education is designed for
                      career professionals seeking
                    </p>
                    <a className="aborder4" href="business-visa.html">
                      <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* services featurs end */}
      {/* featurs area start here */}
      <section className="featurs-services pt-90 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={abouttwo} alt="" />
                  </a>
                </div>
                <div className="features__content">
                  <h3 className="features__content-title">
                    {" "}
                    <a href="business-visa.html">Business Visa</a>{" "}
                  </h3>
                  <p>
                    We helped with other family based employment based and
                    investment based Immigration.
                  </p>
                  <a href="services.html">
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={abouttwo} alt="" />
                  </a>
                </div>
                <div className="features__content">
                  <h3 className="features__content-title">
                    {" "}
                    <a href="business-visa.html">Students Visa </a>{" "}
                  </h3>
                  <p>
                    We helped with other family based employment based and
                    investment based Immigration.
                  </p>
                  <a href="business-visa.html">
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={abouttwo} alt="" />
                  </a>
                </div>
                <div className="features__content">
                  <h3 className="features__content-title">
                    {" "}
                    <a href="business-visa.html">Work &amp; Job Visa</a>{" "}
                  </h3>
                  <p>
                    We helped with other family based employment based and
                    investment based Immigration.
                  </p>
                  <a href="business-visa.html">
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <div className="features">
                <div className="features__thumb">
                  <a href="services.html">
                    {" "}
                    <img src={abouttwo} alt="" />
                  </a>
                </div>
                <div className="features__content">
                  <h3 className="features__content-title">
                    {" "}
                    <a href>Tourist &amp; Visitor Visa</a>{" "}
                  </h3>
                  <p>
                    We helped with other family based employment based and
                    investment based Immigration.
                  </p>
                  <a href="business-visa.html">
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* featurs area end here */}
      {/* Ab-fact-area start */}
      <div
        className="abfact-area services-vrly pt-85 pb-285"
        style={{ backgroundImage: "url(" + services + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center">
                <h1 className="counter-count">
                  <span className="counter">25</span>k+
                </h1>
                <span>Happy Clients &amp; Students</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter-count">
                  <span className="counter">80</span>+
                </h1>
                <span>Countries Affiliation</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter">360</h1>
                <span>Top University Partner</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter-count">
                  <span className="counter">23</span>k+
                </h1>
                <span>Visa &amp; Immigration</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Ab-fact-area end */}
      {/* intro-area start */}
      <section className="intro-area">
        <div className="container">
          <div className="row service-intro-top g-0">
            <div className="col-xxl-6 col-xl-6 col-lg-6 d-flex align-items-center">
              <div className="section_title_wrapper pl-50 pr-70">
                <span className="subtitle">Working Process</span>
                <h2 className="section-title">
                  We Take 1-2 Working Months For Processing
                </h2>
                <p className="pt-30 pb-25 ">
                  For the last 25 years, We have helped students, business
                  persons, tourists, clients with medical needs. There are many
                  variations of passages of Lorem Ipsum available.
                </p>
                <div className="check-use mb-40">
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Requests
                  </a>
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Apply
                  </a>
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Service
                  </a>
                </div>
                <div className="abinfro-btn d-flex align-items-center">
                  <a href="services.html" className="theme-btn">
                    See Packages
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="intro-right">
                <img src={aboutus} alt="" />
                <div className="intro-btn">
                  <a
                    className="play-btn popup-video"
                    href="https://www.youtube.com/watch?v=pNje3bWz7V8"
                  >
                    <i className="flaticon-play-button" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* intro-area end */}
      {/* abbrand-area start */}
      {/* <div className="abbrand-area pt-120 pb-120 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
          <div className="container">
          <Slide/>
          </div>
        </div> */}
      {/* abbrand-area end */}
    </div>
  );
};

export default Main;
