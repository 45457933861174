/* eslint-disable import/no-anonymous-default-export */
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import One from "../../assets/img/hero2/hero-2.jpg";
import Two from "../../assets/img/hero2/hero-1.jpg";
import Three from "../../assets/img/hero2/hero-3.jpg";
import Four from "../../assets/img/hero2/hero-4.jpg";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default () => {
  const swiper = useSwiper();
  return (
    <section className="slider-area fix">
      <div className="slider-active swiper-container">
        <div className="swiper-wrapper">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: false }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => swiper.slideNext()}
          >
            <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">
              <div
                className="slide-bg"
                style={{ backgroundImage: "url(" + One + ")" }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="aslider aslider-2 z-index">
                      <span
                        className="slider-top-text slider-top-text2"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        AUSTRALIA
                      </span>
                      <h2
                        className="aslider--title aslider--title-2 mb-20"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        STUDY OR MIGRATE TO AUSTRALIA
                      </h2>
                      <p
                        className="aslider--subtitle aslider--subtitle-2 mb-40"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        8 of the world’s top Universities
                        <br />
                        22,000 course options at one of the 1,100 globally
                        recognised institutions{" "}
                      </p>
                      <div
                        className="banner-content"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        {/* <form
                          className="form"
                          name="store"
                          id="store"
                          method="post"
                          action=""
                        >
                          <div className="form-inner">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                              />
                              <div className="input-group-append">
                                <button
                                  className="theme-btn theme-color"
                                  type="button"
                                >
                                  Check Availability{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">
              <div
                className="slide-bg"
                style={{ backgroundImage: "url(" + Two + ")" }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="aslider aslider-2 z-index">
                      <span
                        className="slider-top-text slider-top-text2"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        CANADA
                      </span>
                      <h2
                        className="aslider--title aslider--title-2 mb-20"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        STUDY OR MOVE TO CANADA
                      </h2>
                      <p
                        className="aslider--subtitle aslider--subtitle-2 mb-40"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        100+ Public and Private Institutions
                        <br /> More than 30% International Students{" "}
                      </p>
                      <div
                        className="banner-content"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        {/* <form
                          className="form"
                          name="store"
                          id="store"
                          method="post"
                          action=""
                        >
                          <div className="form-inner">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                              />
                              <div className="input-group-append">
                                <button
                                  className="theme-btn theme-color"
                                  type="button"
                                >
                                  Check Availability{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">
              <div
                className="slide-bg"
                style={{ backgroundImage: "url(" + Three + ")" }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="aslider aslider-2 z-index">
                      <span
                        className="slider-top-text slider-top-text2"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        USA
                      </span>
                      <h2
                        className="aslider--title aslider--title-2 mb-20"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        STUDY IN USA
                      </h2>
                      <p
                        className="aslider--subtitle aslider--subtitle-2 mb-40"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        Best Educational Institutions in the World
                        <br /> Recognized Degree Worldwide{" "}
                      </p>
                      <div
                        className="banner-content"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        {/* <form
                          className="form"
                          name="store"
                          id="store"
                          method="post"
                          action=""
                        >
                          <div className="form-inner">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                              />
                              <div className="input-group-append">
                                <button
                                  className="theme-btn theme-color"
                                  type="button"
                                >
                                  Check Availability{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">
              <div
                className="slide-bg"
                style={{ backgroundImage: "url(" + Four + ")" }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="aslider aslider-2 z-index">
                      <span
                        className="slider-top-text slider-top-text2"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        GERMANY
                      </span>
                      <h2
                        className="aslider--title aslider--title-2 mb-20"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        STUDY IN GERMANY
                      </h2>
                      <p
                        className="aslider--subtitle aslider--subtitle-2 mb-40"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        HIGH QUALITY EDUCATION
                        <br /> LOW TUITION FEES{" "}
                      </p>
                      <div
                        className="banner-content"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        {/* <form
                          className="form"
                          name="store"
                          id="store"
                          method="post"
                          action=""
                        >
                          <div className="form-inner">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                              />
                              <div className="input-group-append">
                                <button
                                  className="theme-btn theme-color"
                                  type="button"
                                >
                                  Check Availability{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <button onClick={() => swiper.slideNext()}>
              Slide to the next slide
            </button>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
